<template>
  <router-view/>
  <notifications group="appNotify" position="top center" duration="1"/>
</template>

<script>
export default {
  name: 'App'
}
</script>
