import { createRouter, createWebHistory } from 'vue-router'
import AppView from '../views/AppView.vue'
import LoginView from '../views/LoginView.vue'
import AutoLoginView from '../views/AutoLoginView.vue'
import NotFound from '../views/NotFound.vue'
import HomeView from '../views/AppView/HomeView.vue'
import TodayTaskView from '../views/AppView/TodayTaskView.vue'
import RewardTargetView from '../views/AppView/RewardTargetView.vue'
import AssignTaskView from '../views/AppView/AssignTaskView.vue'
import SetRewardGoalsView from '../views/AppView/SetRewardGoalsView.vue'
import TargetConcernAssignTaskView from '../views/AppView/TargetConcernAssignTaskView.vue'
import MyAccountView from '../views/AppView/MyAccountView.vue'
import store from '../store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/autologin/:token',
    name: 'autologin',
    component: AutoLoginView
  },
  {
    path: '/',
    component: AppView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'todaytask',
        name: 'todaytask',
        component: TodayTaskView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'rewardTarget',
        name: 'rewardTarget',
        component: RewardTargetView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'targetConcernAssigntask',
        name: 'targetConcernAssigntask',
        component: TargetConcernAssignTaskView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'assigntask',
        name: 'assigntask',
        component: AssignTaskView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'setrewardgoals',
        name: 'setrewardgoals',
        component: SetRewardGoalsView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'myaccount',
        name: 'myaccount',
        component: MyAccountView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AppView/AboutView.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 檢查使用者是否已登入
    const isAuthenticated = store.getters.isAuthenticated

    // 如果目標頁面需要驗證，但使用者未登入，則導向登入頁面
    if (to.name !== 'login' && !isAuthenticated) {
      next({ name: 'login' })
    } else {
      // 如果使用者已登入，先驗證Token是否過期
      if (isAuthenticated) {
        // 呼叫驗證Token的action
        const verifyToken = await store.dispatch('verifyToken')
        if (verifyToken === 200) {
          if (to.name === 'login') {
            next({ name: 'home' })
          } else {
            next() // 允許進入目標頁面
          }
        } else {
          const user = null
          const token = null
          store.commit('setToken', { user, token })
          next({ name: 'login' })
        }
      }
      next()
    }
  } else {
    next()
  }
})

export default router
