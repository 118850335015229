<template>
  <div class="d-flex align-center" style="height: 100vh">
    <v-sheet width="300" class="mx-auto">
      <div class="text-center mb-4">
        <img src="./img/icons/remind_icon1.jpg" alt="App Logo" style="max-width: 250px; max-height: 250px;">
      </div>
      <v-form fast-fail @submit.prevent="login">
        <v-text-field  variant="underlined" v-model="username" label="帳號 User Name *"></v-text-field>
        <v-text-field type="password" variant="underlined" v-model="abcd" label="密碼 Password *"></v-text-field>
        <v-text-field variant="underlined" v-model="companyCode" label="公司 Company *"></v-text-field>
        <a href="/forgotpassword" class="text-body-2 font-weight-regular">忘記密碼 Forgot Password?</a>
        <v-btn type="submit" variant="outlined" color="primary" block class="mt-2">Sign in</v-btn>
      </v-form>
      <div class="mt-2">
        <p class="text-body-2">Don't have an account? <a href="/signup">註冊 Sign Up</a></p>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import axios from 'axios'
const Base64 = require('js-base64').Base64

export default {
  name: 'LoginView',
  data: () => ({
    username: '',
    abcd: '',
    companyCode: 'effectstudiodevelop'
  }),
  methods: {
    login () {
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      // console.log('api: ' + apiUrl)

      if (!vm.username || !vm.abcd || !vm.companyCode) {
        vm.$notify({
          group: 'appNotify',
          type: 'warn',
          title: 'Login Failed',
          text: 'Please note that required fields cannot be empty'
        })
        return
      }

      // console.log(apiUrl + `/Account/Login?email=${Base64.encode(vm.username)}&abcd=${Base64.encode(vm.abcd)}&companyCode=${Base64.encode(vm.companyCode)}`)
      axios.post(apiUrl + `/Account/Login?email=${Base64.encode(vm.username)}&abcd=${Base64.encode(vm.abcd)}&companyCode=${Base64.encode(vm.companyCode)}`, {}).then((response) => {
        console.log(response.data) // This will display the response data
        const user = response.data.username
        const token = response.data.token
        const roles = response.data.roles.toString()
        // console.log(roles)
        vm.$store.dispatch('loginUser', { user, token, roles })

        vm.$notify({
          group: 'appNotify',
          type: 'success',
          title: 'Login Successful',
          text: 'Welcome to use Remind App'
        })

        vm.$router.push('/')
      })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error('Error:', error)
          if (error.response.status === 401) {
            vm.$notify({
              group: 'appNotify',
              type: 'warn',
              title: 'Login Failed',
              text: 'Incorrect username or password'
            })
          } else {
            vm.$notify({
              group: 'appNotify',
              type: 'error',
              title: 'Login Failed',
              text: 'System issue, please contact the administrator'
            })
          }
        })
    }
  }
}
</script>
