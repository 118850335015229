<template>
  <div id="todayTaskView">
    <v-container>
      <v-row align="center" justify="center">
        <!-- 昨日未完成任務 -->
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-title>Yesterday Unfinished Task List 昨日未完成任務</v-card-title>
            <!--v-card-title>{{ formattedDate }}</v-card-title-->
            <v-card-text>
              <v-list>
                <v-list-item-group v-if="yesterdayTaskItems.length > 0">
                  <v-list-item v-for="(item, index) in yesterdayTaskItems" :key="index">
                    <template v-if="!item.isEditing">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.task }}</v-list-item-title>
                        <v-list-item-subtitle>
                          Priority: <span v-html="formattedPriority(item.priority)"></span> | Status: <span v-html="formattedStatus(item.status)"></span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle  style="margin-top: 5px;">
                          <v-chip :color="item.isCaregiver ? 'red': ''">
                            Assigned by {{ item.createUser.name }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-divider/>
                        <p>
                          Note:<br>
                          {{ item.note }}
                        </p>
                        <v-list-item-subtitle class="text-right">
                          <!--v-btn @click="editYesterdayTask(index)" color="primary" variant="tonal" class="mr-1">Edit</v-btn-->
                          <!--v-btn @click="beforeDeleteTask(item.id)" color="error" variant="tonal">Delete</v-btn-->
                          <v-btn @click="moveToTodayTask(index)" color="primary" variant="tonal" class="mr-1">Move to Today</v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-else>
                      <v-list-item-content>
                        <v-text-field v-model="item.task" label="Task Item 工作項目 (Not editable)" readonly></v-text-field>
                        <v-select
                          v-model="item.priority"
                          :items="priorities"
                          label="Priority 優先權 (Not editable)"
                          item-title="label"
                          item-value="value"
                          readonly
                        ></v-select>
                        <v-select
                          v-model="item.status"
                          :items="statuses"
                          label="Status 狀態"
                          item-title="label"
                          item-value="value"
                        ></v-select>
                        <v-textarea v-model="item.note" label="Note 備註"></v-textarea>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="saveYesterdayTask(index)" color="primary" class="mr-1" icon>
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn @click="cancelYesterdayEdit(index)" color="error" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                    <v-divider style="border-width: 3px !important; border-color: #000 !important; color: #000 !important;" v-if="index !== taskItems.length - 1" />
                  </v-list-item>
                </v-list-item-group>
                <v-alert v-else :value="true" color="info">No tasks for today.</v-alert>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- 今日任務 -->
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-title>Today Task List 今日任務列表</v-card-title>
            <v-card-title>{{ formattedDate }}</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-group v-if="taskItems.length > 0">
                  <v-list-item v-for="(item, index) in taskItems" :key="index">
                    <template v-if="!item.isEditing">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.task }}</v-list-item-title>
                        <v-list-item-subtitle>
                          Priority: <span v-html="formattedPriority(item.priority)"></span> | Status: <span v-html="formattedStatus(item.status)"></span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle  style="margin-top: 5px;">
                          <v-chip :color="item.isCaregiver ? 'red': ''">
                            Assigned by {{ item.createUser.name }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-divider/>
                        <p>
                          Note:<br>
                          {{ item.note }}
                        </p>
                        <v-list-item-subtitle class="text-right">
                          <v-btn @click="editTask(index)" color="primary" variant="tonal" class="mr-1">Edit</v-btn>
                          <!--v-btn @click="beforeDeleteTask(item.id)" color="error" variant="tonal">Delete</v-btn-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-else>
                      <v-list-item-content>
                        <v-text-field v-model="item.task" label="Task Item 工作項目 (Not editable)" readonly></v-text-field>
                        <v-select
                          v-model="item.priority"
                          :items="priorities"
                          label="Priority 優先權 (Not editable)"
                          item-title="label"
                          item-value="value"
                          readonly
                        ></v-select>
                        <v-select
                          v-model="item.status"
                          :items="statuses"
                          label="Status 狀態"
                          item-title="label"
                          item-value="value"
                        ></v-select>
                        <v-textarea v-model="item.note" label="Note 備註"></v-textarea>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="saveTask(index)" color="primary" class="mr-1" icon>
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn @click="cancelEdit(index)" color="error" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                    <v-divider style="border-width: 3px !important; border-color: #000 !important; color: #000 !important;" v-if="index !== taskItems.length - 1" />
                  </v-list-item>
                </v-list-item-group>
                <v-alert v-else :value="true" color="info">No tasks for today.</v-alert>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- 今日後面的任務 -->
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-title>The tasks for the next five days</v-card-title>
            <v-card-title>後續五天的任務</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-group v-if="followingTaskItems.length > 0">
                  <v-list-item v-for="(item, index) in followingTaskItems" :key="index">
                    <template v-if="!item.isEditing">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.task }}</v-list-item-title>
                        <v-list-item-subtitle>
                          Priority: <span v-html="formattedPriority(item.priority)"></span> | Status: <span v-html="formattedStatus(item.status)"></span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Date: {{ item.executionTime }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="margin-top: 5px;">
                          <v-chip :color="item.isCaregiver ? 'red': ''">
                            Assigned by {{ item.createUser.name }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-divider/>
                        <p>
                          Note:<br>
                          {{ item.note }}
                        </p>
                        <v-list-item-subtitle class="text-right">
                          <v-btn @click="editNextTask(index)" color="primary" variant="tonal" class="mr-1">Edit</v-btn>
                          <!--v-btn @click="beforeDeleteTask(item.id)" color="error" variant="tonal">Delete</v-btn-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template v-else>
                      <v-list-item-content>
                        <v-text-field v-model="item.task" label="Task Item 工作項目 (Not editable)" readonly></v-text-field>
                        <v-select
                          v-model="item.priority"
                          :items="priorities"
                          label="Priority 優先權 (Not editable)"
                          item-title="label"
                          item-value="value"
                          readonly
                        ></v-select>
                        <v-select
                          v-model="item.status"
                          :items="statuses"
                          label="Status 狀態"
                          item-title="label"
                          item-value="value"
                        ></v-select>
                        <v-textarea v-model="item.note" label="Note 備註"></v-textarea>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="saveNextTask(index)" color="primary" class="mr-1" icon>
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                        <v-btn @click="cancelNextEdit(index)" color="error" icon>
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                    <v-divider style="border-width: 3px !important; border-color: #000 !important; color: #000 !important;" v-if="index !== followingTaskItems.length - 1" />
                  </v-list-item>
                </v-list-item-group>
                <v-alert v-else :value="true" color="info">No tasks for the next five days</v-alert>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../../store'

export default {
  data () {
    return {
      selectedDate: new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      yesterdaySelectedDate: new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      taskItems: [],
      priorities: store.getters.taskPriorities,
      statuses: store.getters.taskStatuses,
      followingTaskItems: [],
      yesterdayTaskItems: []
    }
  },
  mounted () {
    this.loadYesterdayTasks()
    this.loadTasks()
    this.loadNextTasks()
  },
  methods: {
    loadYesterdayTasks () {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL

      var startDateObject = new Date(vm.selectedDate)
      startDateObject.setDate(startDateObject.getDate() - 1)
      var startDate = startDateObject.getFullYear() + '/' +
                         String(startDateObject.getMonth() + 1).padStart(2, '0') + '/' +
                         String(startDateObject.getDate()).padStart(2, '0')
      axios.post(apiUrl + '/UserTask/SearchMyTasks', {
        date: startDate,
        keyword: '',
        status: ['ToDo', 'InProgress', 'NotResolvable', 'PendingDiscussion'],
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.yesterdayTaskItems = response.data.data
        vm.sortedYesterdayTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    loadTasks () {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/UserTask/SearchMyTasks', {
        date: vm.selectedDate,
        keyword: '',
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.taskItems = response.data.data
        vm.sortedTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    loadNextTasks () {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL

      var startDateObject = new Date(vm.selectedDate)
      startDateObject.setDate(startDateObject.getDate() + 1)
      var startDate = startDateObject.getFullYear() + '/' +
                         String(startDateObject.getMonth() + 1).padStart(2, '0') + '/' +
                         String(startDateObject.getDate()).padStart(2, '0')
      var endDateObject = new Date(vm.selectedDate)
      endDateObject.setDate(endDateObject.getDate() + 5)
      var endDate = endDateObject.getFullYear() + '/' +
                         String(endDateObject.getMonth() + 1).padStart(2, '0') + '/' +
                         String(endDateObject.getDate()).padStart(2, '0')
      axios.post(apiUrl + '/UserTask/SearchMyTasksByDateRange', {
        startDate: startDate,
        endDate: endDate,
        keyword: '',
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.followingTaskItems = response.data.data
        vm.sortedNextTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    editTask (index) {
      this.taskItems[index].isEditing = true
    },
    saveTask (index) {
      // console.log(this.taskItems[index])
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(apiUrl + '/UserTask/' + vm.taskItems[index].id, {
        task: vm.taskItems[index].task,
        priority: vm.taskItems[index].priority,
        note: vm.taskItems[index].note,
        timeSpent: vm.taskItems[index].timeSpent,
        status: vm.taskItems[index].status,
        executionTime: vm.taskItems[index].executionTime
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.taskItems[index].isEditing = false
        vm.loadTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    cancelEdit (index) {
      this.taskItems[index].isEditing = false
    },
    editNextTask (index) {
      this.followingTaskItems[index].isEditing = true
    },
    saveNextTask (index) {
      // console.log(this.taskItems[index])
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(apiUrl + '/UserTask/' + vm.followingTaskItems[index].id, {
        task: vm.followingTaskItems[index].task,
        priority: vm.followingTaskItems[index].priority,
        note: vm.followingTaskItems[index].note,
        timeSpent: vm.followingTaskItems[index].timeSpent,
        status: vm.followingTaskItems[index].status,
        executionTime: vm.followingTaskItems[index].executionTime
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.followingTaskItems[index].isEditing = false
        vm.loadNextTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    cancelNextEdit (index) {
      this.followingTaskItems[index].isEditing = false
    },
    editYesterdayTask (index) {
      this.yesterdayTaskItems[index].isEditing = true
    },
    saveYesterdayTask (index) {
      // console.log(this.yesterdayTaskItems[index])
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(apiUrl + '/UserTask/' + vm.yesterdayTaskItems[index].id, {
        task: vm.yesterdayTaskItems[index].task,
        priority: vm.yesterdayTaskItems[index].priority,
        note: vm.yesterdayTaskItems[index].note,
        timeSpent: vm.yesterdayTaskItems[index].timeSpent,
        status: vm.yesterdayTaskItems[index].status,
        executionTime: vm.yesterdayTaskItems[index].executionTime
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.yesterdayTaskItems[index].isEditing = false
        vm.loadYesterdayTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    cancelYesterdayEdit (index) {
      this.yesterdayTaskItems[index].isEditing = false
    },
    formattedPriority (priorityValue) {
      if (priorityValue === 'VeryHigh') {
        return '<span style="color:red;">Very High</span>'
      } else if (priorityValue === 'High') {
        return '<span>High</span>'
      } else if (priorityValue === 'Medium') {
        return '<span>Medium</span>'
      } else if (priorityValue === 'Low') {
        return '<span>Low</span>'
      } else {
        return '<span>' + priorityValue + '</span>'
      }
    },
    formattedStatus (statusValue) {
      if (statusValue === 'ToDo') {
        return '<span>To-Do</span>'
      } else if (statusValue === 'InProgress') {
        return '<span>In Progress</span>'
      } else if (statusValue === 'Completed') {
        return '<span style="color:green;">Completed</span>'
      } else if (statusValue === 'NotResolvable') {
        return '<span style="color:red;">Not Resolvable</span>'
      } else if (statusValue === 'PendingDiscussion') {
        return '<span style="color:red;">Pending Discussion</span>'
      } else {
        return '<span>' + statusValue + '</span>'
      }
    },
    sortedYesterdayTasks () {
      this.yesterdayTaskItems = this.yesterdayTaskItems.slice().sort((a, b) => {
        if (a.status === 'Completed' && b.status !== 'Completed') {
          return 1 // Move completed tasks to the end
        } else if (a.status !== 'Completed' && b.status === 'Completed') {
          return -1 // Move completed tasks to the end
        } else {
          // Sort by priority if statuses are the same
          const priorityOrder = {
            VeryHigh: 0,
            High: 1,
            Medium: 2,
            Low: 3
          }
          return priorityOrder[a.priority] - priorityOrder[b.priority]
        }
      })
    },
    sortedTasks () {
      this.taskItems = this.taskItems.slice().sort((a, b) => {
        if (a.status === 'Completed' && b.status !== 'Completed') {
          return 1 // Move completed tasks to the end
        } else if (a.status !== 'Completed' && b.status === 'Completed') {
          return -1 // Move completed tasks to the end
        } else {
          // Sort by priority if statuses are the same
          const priorityOrder = {
            VeryHigh: 0,
            High: 1,
            Medium: 2,
            Low: 3
          }
          return priorityOrder[a.priority] - priorityOrder[b.priority]
        }
      })
    },
    sortedNextTasks () {
      this.followingTaskItems = this.followingTaskItems.slice().sort((a, b) => {
        if (a.status === 'Completed' && b.status !== 'Completed') {
          return 1 // Move completed tasks to the end
        } else if (a.status !== 'Completed' && b.status === 'Completed') {
          return -1 // Move completed tasks to the end
        } else {
          // Sort by priority if statuses are the same
          const priorityOrder = {
            VeryHigh: 0,
            High: 1,
            Medium: 2,
            Low: 3
          }
          return priorityOrder[a.priority] - priorityOrder[b.priority]
        }
      })
    },
    moveToTodayTask (index) {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(apiUrl + '/UserTask/' + vm.yesterdayTaskItems[index].id, {
        task: vm.yesterdayTaskItems[index].task,
        priority: vm.yesterdayTaskItems[index].priority,
        note: vm.yesterdayTaskItems[index].note,
        timeSpent: vm.yesterdayTaskItems[index].timeSpent,
        status: vm.yesterdayTaskItems[index].status,
        executionTime: vm.selectedDate
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.loadYesterdayTasks()
        vm.loadTasks()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  },
  computed: {
    formattedDate () {
      const date = new Date(this.selectedDate)
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
  }
}
</script>

<style>
#todayTaskView {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
}

.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
