<template>
  <div id="rewardTargetView">
    <h1>Reward Goals 獎勵目標</h1>
    <v-card v-for="(rewardTarget, index) in sortedRewardTargets" :key="index" class="mb-4"  variant="outlined">
      <v-card-title>{{ rewardTarget.name }}</v-card-title>
      <v-card-text>
        <p>獎勵內容：{{ rewardTarget.content }}</p>
        <p>時間：{{ rewardTarget.startTime }} ~ {{ rewardTarget.endTime }}</p>
        <p>時間：{{ rewardTarget.startTime }} ~ {{ rewardTarget.endTime }}</p>
        <v-divider></v-divider>
        <h4>目標條件</h4>
        <p>Very High： {{ rewardTarget.actualVeryHigh }} / {{ rewardTarget.veryHighAchievedCount }}</p>
        <p>High： {{ rewardTarget.actualHigh }} / {{ rewardTarget.highAchievedCount }}</p>
        <p>Medium： {{ rewardTarget.actualMedium }} / {{ rewardTarget.mediumAchievedCount }}</p>
        <p>Low： {{ rewardTarget.actualLow }} / {{ rewardTarget.lowAchievedCount }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="outlined" @click="seeAchievedTask(rewardTarget.id, rewardTarget.name)" :color="'primary'">Achieved Task</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="seeAchievedTaskDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
          <v-toolbar
              dark
              color="teal"
          >
            <v-btn
              icon
              dark
              @click="seeAchievedTaskDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Achieved Task 完成的任務 - {{ selectedRewardTargetName }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <!-- 工作列表 -->
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8">
                <div class="inner-content">
                <v-list class="custom-list">
                  <div v-for="(group, priority) in groupedAchievedTasks" :key="priority">
                    <div class="group-title" v-if="group.length > 0">{{ priority }}</div>
                    <v-list-item v-for="task in group" :key="task.id" class="custom-list-item">
                      <v-list-item-content class="custom-list-item-content">
                        <v-list-item-title class="custom-list-item-title">{{ task.task }}</v-list-item-title>
                        <v-list-item-subtitle class="custom-list-item-subtitle">
                          {{ task.executionTime }}
                          <span class="badge priority-badge" v-html="formattedPriority(task.priority)"></span>
                          <!--span class="badge assignee-badge">{{ task.createUser.name }}</span-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
                <div class="placeholder"></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../../store'

export default {
  data () {
    return {
      rewardTargets: [],
      seeAchievedTaskDialog: false,
      selectedRewardTargetName: '',
      selectedRewardTarget: null
    }
  },
  mounted () {
    this.loadRewardTargets()
  },
  methods: {
    loadRewardTargets () {
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/RewardTarget/My', {
        keyword: '',
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.rewardTargets = response.data.data
        console.log(vm.rewardTargets)
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    seeAchievedTask (achievedTaskId, achievedTaskName) {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      vm.selectedRewardTargetName = achievedTaskName
      axios.get(apiUrl + '/RewardTarget/My/' + achievedTaskId, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        console.log(response.data)
        vm.seeAchievedTaskDialog = true
        vm.selectedRewardTarget = response.data
      })
    },
    formattedPriority (priorityValue) {
      if (priorityValue === 'VeryHigh') {
        return '<span style="color:red;">Very High</span>'
      } else if (priorityValue === 'High') {
        return '<span>High</span>'
      } else if (priorityValue === 'Medium') {
        return '<span>Medium</span>'
      } else if (priorityValue === 'Low') {
        return '<span>Low</span>'
      } else {
        return '<span>' + priorityValue + '</span>'
      }
    },
    formattedStatus (statusValue) {
      if (statusValue === 'ToDo') {
        return '<span>To-Do</span>'
      } else if (statusValue === 'InProgress') {
        return '<span>In Progress</span>'
      } else if (statusValue === 'Completed') {
        return '<span style="color:green;">Completed</span>'
      } else if (statusValue === 'NotResolvable') {
        return '<span style="color:red;">Not Resolvable</span>'
      } else if (statusValue === 'PendingDiscussion') {
        return '<span style="color:red;">Pending Discussion</span>'
      } else {
        return '<span>' + statusValue + '</span>'
      }
    }
  },
  computed: {
    sortedRewardTargets () {
      this.rewardTargets.forEach((rewardTarget) => {
        rewardTarget.actualVeryHigh = rewardTarget.achievedTask.filter(task => task.priority === 'VeryHigh').length
        rewardTarget.actualHigh = rewardTarget.achievedTask.filter(task => task.priority === 'High').length
        rewardTarget.actualMedium = rewardTarget.achievedTask.filter(task => task.priority === 'Medium').length
        rewardTarget.actualLow = rewardTarget.achievedTask.filter(task => task.priority === 'Low').length
      })
      // console.log(this.rewardTargets)

      return this.rewardTargets
    },
    groupedAchievedTasks () {
      const groups = {}
      groups.VeryHigh = []
      groups.High = []
      groups.Medium = []
      groups.Low = []

      if (this.selectedRewardTarget == null) {
        return []
      }
      for (const task of this.selectedRewardTarget.achievedTask) {
        // console.log(task.executionTime)
        const priority = task.priority
        if (!groups[priority]) {
          groups[priority] = []
        }
        groups[priority].push(task)
      }

      return groups
    }
  }
}
</script>

<style>
#rewardTargetView {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.task-list {
  max-height: calc(100vh - 64px); /* Set a maximum height for scrolling */
  overflow-y: scroll;
}
.task-list::-webkit-scrollbar {
    display: none;
}
.placeholder {
  height: 1000px; /* Adjust the height as needed */
}

.custom-list {
  background-color: #f5f5f5; /* Customize the background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Customize the shadow */
  padding: 16px;
}

.custom-list-item {
  margin-bottom: 8px; /* Add some spacing between items */
  border: 1px solid #ccc; /* Add a border to items */
  border-radius: 4px;
}

.custom-list-item-content {
  padding: 8px;
}

.custom-list-item-title {
  font-weight: bold;
}

.custom-list-item-subtitle {
  color: #888;
}

.group-title {
  background-color: #f5f5f5;
  padding: 4px 16px;
  font-weight: bold;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: bold;
}

.priority-badge {
  background-color: #ffcc00; /* Customize badge color */
  color: #333; /* Customize text color */
}

.status-badge {
  background-color: #00cc66; /* Customize badge color */
  color: #fff; /* Customize text color */
}

.assignee-badge {
  background-color: #3366cc; /* Customize badge color */
  color: #fff; /* Customize text color */
}
</style>
