<template>
  <v-app>
    <!-- head menu 開始 -->
    <v-app-bar app>
        <v-app-bar-nav-icon @click="toggleMenu" v-if="!menuOpen">
        <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>{{ appName }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app v-model="menuOpen">
        <v-list>
        <v-list-item v-for="item in menuItems" :key="item.title" @click="navigateTo(item.route)">
            <v-row align="center">
            <v-col cols="auto">
                <v-icon>{{ item.icon }}</v-icon>
            </v-col>
            <v-col cols="auto">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-col>
            </v-row>
        </v-list-item>
        </v-list>
    </v-navigation-drawer>
    <!-- head menu 結束 -->
    <v-main>
        <transition name="fade">
        <router-view/>
        </transition>
    </v-main>
    <BottomMenu></BottomMenu>

    <!-- Contact 開始 -->
    <v-dialog
        v-model="contactDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
        <v-toolbar
            dark
            color="teal"
        >
            <v-btn
            icon
            dark
            @click="contactDialog = false"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Contact Info.</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
            three-line
            subheader
        >
            <v-subheader>Development Company</v-subheader>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Company Name</v-list-item-title>
                <v-list-item-subtitle>影響視覺科技有限公司 EFFECT TECH CO., LTD.</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Contact</v-list-item-title>
                <v-list-item-subtitle>Name: 林麟杰 007</v-list-item-subtitle>
                <v-list-item-subtitle>Phone: 0928522713</v-list-item-subtitle>
                <v-list-item-subtitle>Email: james.0lin7@gmail.com</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
            <v-subheader>About Company</v-subheader>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Intro</v-list-item-title>
                <v-list-item-subtitle class="wrap-text">影響視覺科技堅持故事腳本內容是靈魂，在內容裡以簡馭繁及化繁為簡，透由不同之多媒體工具將內容視覺化，團隊採專業分工，擅於跨領域整合，製作由內容延伸之原創圖像/ 視覺系統、動靜態/動畫影像、並配合新科技將之呈現，如光雕投影、互動科技、虛擬實境 AR/VR 等。 若有任何為您服務或合作的可能，歡迎聯繫聊聊。</v-list-item-subtitle>
                <v-list-item-subtitle class="wrap-text">EFFECT TECH adheres to the belief that the soul of storytelling lies within the content. We simplify complex ideas and transform intricacies into simplicity. Utilizing various multimedia tools, we visualize the content and our team specializes in professional division of labor. We excel in interdisciplinary integration, producing original graphics/visual systems, dynamic/static animation, and incorporating cutting-edge technologies such as projection mapping, interactive technology, virtual reality (VR), and augmented reality (AR) to present the content. If there are any possibilities for us to serve you or collaborate, please feel free to get in touch for a chat.</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
            three-line
            subheader
        >
            <v-subheader>Developer</v-subheader>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>Nathan Hou</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <v-list-item>
            <v-list-item-content>
                <v-list-item-title>Contact</v-list-item-title>
                <v-list-item-subtitle>Name: Nathan Hou</v-list-item-subtitle>
                <v-list-item-subtitle>Email: effect.nathan@gmail.com</v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-card>
    </v-dialog>
    <!-- Contact 結束 -->
  </v-app>
</template>

<script>
import BottomMenu from '../components/BottomMenu.vue'
import axios from 'axios'
import store from '../store'
// import OneSignal from '@onesignal/onesignal-vue3'

export default {
  name: 'AppView',
  components: {
    BottomMenu
  },
  data: () => ({
    appName: '',
    menuOpen: false,
    menuItems: [],
    contactDialog: false
  }),
  beforeCreate () {
    // this.$OneSignal.showSlidedownPrompt()
  },
  created () {
    var vm = this
    const apiUrl = process.env.VUE_APP_API_URL
    vm.appName = process.env.VUE_APP_NAME
    setInterval(function () {
      axios.post(apiUrl + '/Account/UpdateOnlineTime', {}, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        // vm.taskItems = response.data.data
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    }, 5000)

    // menu
    vm.menuItems.push({ title: 'Home', icon: 'mdi-home', route: '/' })
    vm.menuItems.push({ title: 'My Account', icon: 'mdi-account-box', route: '/myaccount' })
    console.log(store.getters.roles)
    if (store.getters.roles.includes('Caregiver')) vm.menuItems.push({ title: 'Set Goals', icon: 'mdi-trophy', route: '/setrewardgoals' })
    vm.menuItems.push({ title: 'Today Task', icon: 'mdi-calendar-check', route: '/todaytask' })
    vm.menuItems.push({ title: 'Assign Task', icon: 'mdi-clipboard-arrow-down-outline', route: '/assigntask' })
    vm.menuItems.push({ title: 'About', icon: 'mdi-information', route: '/about' })
    vm.menuItems.push({ title: 'Contact', icon: 'mdi-phone-classic', route: '/contact' })
    vm.menuItems.push({ title: 'Logout', icon: 'mdi-logout', route: '/logout' })
  },
  methods: {
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    navigateTo (route) {
      if (route === '/contact') {
        this.contactDialog = true
      } else if (route === '/logout') {
        this.$store.dispatch('logoutUser')

        this.$router.push('/login')
      } else {
        this.$router.push(route)
      }
      this.menuOpen = false
    }
  }
}
</script>

<style>
.fade-enter-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.v-list {
  padding: 8px 8px;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}
</style>
