<template>
  <div id="assignTask">
    <h1 v-if="assignTaskType == 1">All 所有人</h1>
    <h1 v-else-if="assignTaskType == 2">Target Concerns 關注對象</h1>
    <h1 v-else>None</h1>
    <div class="d-flex align-center">
      <v-select
        :items="['Name 名字','Last Online Time 最後上線時間', 'Today Task Count 今日任務數量']"
        label="Order Type 排序方式"
        v-model="sortedOrderType"
        @update:modelValue="handleSortedOrderTypeOptionChange"
        class="mr-3"
      >
        <template v-slot:item="{ item, props }">
          <v-list-item v-bind="props">
            <template v-slot:title>
              {{ item.raw }}
            </template>
          </v-list-item>
        </template>
      </v-select>
      <v-btn @click="toggleSortOrder" text>
        排序顺序 <v-icon>{{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
      </v-btn>
    </div>
    <v-card v-for="(employee, index) in sortedEmployees" :key="index" class="mb-4" :color="employee.isConcern ? 'deep-orange' : ''"  variant="outlined">
      <v-card-title>{{ employee.name }}</v-card-title>
      <v-card-text>
        <p>職位：{{ employee.title }}</p>
        <p>上線時間：{{ new Date((new Date(employee.lastOnlineTime)).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toLocaleString().substr(0, 19) }}</p>
        <p>今日任務數量：{{ todayTasks(employee.tasks).length }}</p>
        <p>任務總數量：{{ employee.tasks.length }}</p>
        <v-divider></v-divider>
        <h4>聯絡資訊</h4>
        <p>信箱：{{ employee.email }}</p>
        <p>電話：{{ employee.phone }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="outlined" @click="openAssignTaskDialog(employee.id, employee.name)" :color="employee.isConcern ? 'brown' : 'primary'">Assign Task</v-btn>
        <v-btn variant="outlined" @click="notify(employee.id)" :color="employee.isConcern ? 'brown' : 'primary'">Notify</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn variant="outlined" @click="see(employee.id, employee.name)" :color="employee.isConcern ? 'brown' : 'primary'">Task List</v-btn>
        <v-btn variant="outlined" @click="seeTimeline(employee.id, employee.name)" :color="employee.isConcern ? 'brown' : 'primary'">Task Timeline</v-btn>
      </v-card-actions>
    </v-card>

    <!-- seeTask 開始 -->
    <v-dialog
        v-model="seeTaskDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
          <v-toolbar
              dark
              color="teal"
          >
              <v-btn
              icon
              dark
              @click="seeTaskDialog = false"
              >
              <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Task List 工作列表 - {{ selectedUserName }}</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <!-- 日期選擇器 -->
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  type="date"
                  label="Date"
                  v-model="selectedDate"
                  @change="loadTaskItems"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- 工作列表 -->
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8">
                <v-card>
                  <v-card-title>Task List - {{ formattedDate }}</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item-group v-if="taskItems.length > 0">
                        <v-list-item v-for="(item, index) in taskItems" :key="index">
                          <template v-if="!item.isEditing">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.task }}&nbsp;&nbsp;&nbsp;
                                <v-chip :color="item.isCaregiver ? 'red': ''">
                                  Assigned by {{ item.createUser.name }}
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                 Priority: <span v-html="formattedPriority(item.priority)"></span> | Status: <span v-html="formattedStatus(item.status)"></span>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="text-right">
                                <v-btn @click="notifyTask(index)" color="red" variant="tonal" class="mr-1">Notify</v-btn>
                                <v-btn @click="editTask(index)" color="primary" variant="tonal" class="mr-1">Edit</v-btn>
                                <v-btn @click="beforeDeleteTask(item.id)" color="error" variant="tonal">Delete</v-btn>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>

                          <template v-else>
                            <v-list-item-content>
                              <v-text-field v-model="item.task" label="Task Item 工作項目"></v-text-field>
                              <v-select
                                v-model="item.priority"
                                :items="priorities"
                                label="Priority 優先權"
                                item-title="label"
                                item-value="value"
                              ></v-select>
                              <v-select
                                v-model="item.status"
                                :items="statuses"
                                label="Status 狀態"
                                item-title="label"
                                item-value="value"
                              ></v-select>
                              <v-textarea v-model="item.note" label="Note 備註"></v-textarea>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn @click="saveTask(index)" color="primary" class="mr-1" icon>
                                <v-icon>mdi-check</v-icon>
                              </v-btn>
                              <v-btn @click="cancelEdit(index)" color="error" icon>
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </template>
                          <v-divider v-if="index !== taskItems.length - 1" />
                        </v-list-item>
                      </v-list-item-group>
                      <v-alert v-else :value="true" color="info">No tasks for the current date.</v-alert>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
    </v-dialog>
    <!-- seeTask 結束 -->

    <!-- seeTimelineTask 開始 -->
    <v-dialog
        v-model="seeTimelineTaskDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
          <v-toolbar
              dark
              color="teal"
          >
            <v-btn
              icon
              dark
              @click="seeTimelineTaskDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Task Timeline 工作時間表 - {{ selectedUserName }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <!-- 工作列表 -->
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8">
                <div class="task-list" ref="scrollContainer" @scroll="timelineHandleScroll">
                  <div class="inner-content">
                  <v-list class="custom-list">
                    <div v-for="(group, date) in timelineGroupedTasks" :key="date">
                      <div class="group-title">{{ formatDate(date) }}</div>
                      <!--v-divider :key="'divider-' + date">{{ formatDate(date) }}</v-divider-->
                      <v-list-item v-for="task in group" :key="task.id" class="custom-list-item">
                        <v-list-item-content class="custom-list-item-content" @click="openTimelineTaskModal(task)">
                          <v-list-item-title class="custom-list-item-title">{{ task.task }}</v-list-item-title>
                          <v-list-item-subtitle class="custom-list-item-subtitle">
                            {{ task.executionTime }}
                            <span class="badge priority-badge" v-html="formattedPriority(task.priority)"></span>
                            <span class="badge status-badge" v-html="formattedStatus(task.status)"></span>
                            <span class="badge assignee-badge">{{ task.createUser.name }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list>
                  <div class="placeholder"></div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showTimelineTaskModal" max-width="500">
      <v-card>
        <v-card-title>{{ selectedTimelineTask.task }}</v-card-title>
        <v-card-text>
          <!-- Display detailed task information here -->
          <div>Priority: <span v-html="formattedPriority(selectedTimelineTask.priority)"></span></div>
          <div>Status: <span v-html="formattedStatus(selectedTimelineTask.status)"></span></div>
          <div>Assigned to: {{ selectedTimelineTask.createUser.name }}</div>
          <div>Note: <br>{{ selectedTimelineTask.note }}</div>
          <!-- Add other details as needed -->
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeTimelineTaskModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- seeTimelineTask 結束 -->

    <v-dialog v-model="deleteConfirmation" max-width="400">
      <v-card>
        <v-card-title>Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this task?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteConfirmation = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteTask()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="assignTaskDialog" max-width="500">
      <v-card>
        <v-card-title>Assign Task 分派任務 - {{ selectedUserName }}</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="assignTask">
            <v-text-field v-model="newTask.task" label="Task Name 任務名稱" required></v-text-field>
            <v-select
              v-model="newTask.priority"
              :items="priorities"
              label="Priority 優先權"
              item-title="label"
              item-value="value"
            ></v-select>
            <v-textarea v-model="newTask.note" label="Note 備註"></v-textarea>
            <v-text-field v-model="newTask.executionTime" type="date" label="Execution Time 執行時間"></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="assignTaskDialog = false">Cancel</v-btn>
              <v-btn type="submit" color="primary">Confirm</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import store from '../store'

export default {
  props: {
    assignTaskType: Intl
  },
  data () {
    return {
      employees: [],
      seeTaskDialog: false,
      selectedDate: null,
      taskItems: [],
      selectedUser: '',
      selectedUserName: '',
      deleteConfirmation: false,
      deleteTaskItem: null,
      priorities: store.getters.taskPriorities,
      statuses: store.getters.taskStatuses,
      assignTaskDialog: false,
      newTask: {
        task: '',
        priority: '',
        note: '',
        executionTime: ''
      },
      timelineTasks: [],
      seeTimelineTaskDialog: false,
      timelineVisibleTasks: [],
      timelineCurrentIndex: 0,
      timelineBatchSize: 10, // Number of tasks to load at once
      prevScrollTop: 0,
      timelinePreStart: 0,
      timelinePreEnd: 0,
      selectedTimelineTask: null,
      showTimelineTaskModal: false,
      sortedOrderType: 'Today Task Count 今日任務數量',
      sortOrder: 'desc'
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    loadUsers () {
      // console.log(this.assignTaskType)
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      if (vm.assignTaskType === 1) {
        axios.post(apiUrl + '/UserTask/SearchCompanyUsers', {
          keyword: '',
          pageRequestParameter: {
            isReturnAllDataAndNoPage: true,
            targetPage: 1,
            showCount: 5
          }
        }, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`
          }
        }).then((response) => {
          vm.employees = response.data.data
        })
          .catch((error) => {
            console.error('Error:', error)
          })
      } else if (vm.assignTaskType === 2) {
        axios.post(apiUrl + '/UserTask/SearchTargetConcernUserTasks', {
          keyword: '',
          pageRequestParameter: {
            isReturnAllDataAndNoPage: true,
            targetPage: 1,
            showCount: 5
          }
        }, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`
          }
        }).then((response) => {
          vm.employees = response.data.data
        })
          .catch((error) => {
            console.error('Error:', error)
          })
      } else {
        axios.post(apiUrl + '/UserTask/SearchCompanyUsers', {
          keyword: '',
          pageRequestParameter: {
            isReturnAllDataAndNoPage: true,
            targetPage: 1,
            showCount: 5
          }
        }, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`
          }
        }).then((response) => {
          vm.employees = response.data.data
        })
          .catch((error) => {
            console.error('Error:', error)
          })
      }
    },
    editEmployee (index) {
      // 編輯員工的邏輯，你可以在這裡導航到編輯頁面或彈出編輯對話框等
      // console.log('編輯員工:', this.employees[index])
    },
    assign (userId) {
      // console.log(userId)
    },
    see (userId, username) {
      // console.log(userId)
      this.selectedDate = new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.selectedUser = userId
      this.selectedUserName = username
      this.loadTaskItems()
      this.seeTaskDialog = true
    },
    seeTimeline (userId, username) {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      vm.selectedDate = new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      vm.selectedUser = userId
      vm.selectedUserName = username
      axios.post(apiUrl + '/UserTask/SearchUserTasks', {
        userId: vm.selectedUser,
        keyword: '',
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.timelineTasks = response.data.data
        vm.seeTimelineTaskDialog = true

        let closestIndex = -1
        let closestDateDiff = Infinity
        for (let i = 0; i < vm.timelineTasks.length; i++) {
          const executionDate = vm.timelineTasks[i].executionTime.substr(0, 10)
          const dateDiff = Math.abs(new Date(executionDate) - new Date(vm.selectedDate))

          if (dateDiff < closestDateDiff) {
            closestDateDiff = dateDiff
            closestIndex = i
          }
        }
        vm.timelineCurrentIndex = closestIndex
        // console.log(vm.timelineCurrentIndex)
        vm.timelineUpdateVisibleTasks('initial')
        // console.log(vm.timelineTasks)
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    timelineUpdateVisibleTasks (type) {
      let start = 0
      let end = 0
      // await this.$nextTick()
      if (type === 'initial') {
        start = this.timelineCurrentIndex
        end = Math.min(this.timelineCurrentIndex + this.timelineBatchSize, this.timelineTasks.length)
        this.$nextTick(() => {
          if (this.$refs.scrollContainer) {
            this.$refs.scrollContainer.scrollTop = 10
            // console.log(this.$refs.scrollContainer.scrollTop)
          }
        })
      } else if (type === 'up') {
        start = Math.max(0, this.timelinePreStart - this.timelineBatchSize)
        end = Math.min(this.timelineCurrentIndex + this.timelineBatchSize, this.timelineTasks.length)
        if (this.timelinePreEnd > end) {
          end = this.timelinePreEnd
        }
      } else if (type === 'down') {
        start = Math.max(0, this.timelineCurrentIndex - this.timelineBatchSize)
        if (this.timelinePreStart < start) {
          start = this.timelinePreStart
        }
        end = Math.min(this.timelinePreEnd + this.timelineBatchSize, this.timelineTasks.length)
      }
      // console.log(`data: start ${start}, end ${end}`)

      this.timelineVisibleTasks = this.timelineTasks.slice(start, end)

      this.timelinePreStart = start
      this.timelinePreEnd = end
    },
    timelineHandleScroll: async function (event) {
      const scrollContainer = event.target
      const scrollPosition = scrollContainer.scrollTop
      const maxScroll = scrollContainer.scrollHeight - scrollContainer.clientHeight
      const preScrollHeight = scrollContainer.scrollHeight
      // console.log(scrollContainer.scrollHeight, scrollContainer.clientHeight)
      // console.log(scrollPosition, maxScroll, scrollPosition < this.prevScrollTop, this.timelineCurrentIndex)
      // console.log(event)
      // console.log(`scrollPosition=${scrollPosition}, maxScroll=${maxScroll}, timelineCurrentIndex=${this.timelineCurrentIndex}, scrollPosition=${scrollPosition}, prevScrollTop=${this.prevScrollTop}`)

      if (scrollPosition <= 1 && this.timelineCurrentIndex > 0 && scrollPosition < this.prevScrollTop) {
        // console.log('upupupupup')
        this.timelineCurrentIndex -= this.timelineBatchSize
        await this.timelineUpdateVisibleTasks('up')
        this.$nextTick(() => {
          // console.log(`scrollPosition: ${scrollPosition}, scrollHeight: ${scrollContainer.scrollHeight}, clientHeight: ${scrollContainer.clientHeight}, maxScroll: ${maxScroll}`)
          scrollContainer.scrollTop = scrollContainer.scrollHeight - preScrollHeight
          // console.log(`scrollContainer.scrollTop: ${scrollContainer.scrollTop} = ${scrollPosition} + ${scrollContainer.scrollHeight} - ${maxScroll}`)
        })
      } else if (scrollPosition >= (maxScroll - 1) && this.timelineCurrentIndex < this.timelineTasks.length - 1 && scrollPosition > this.prevScrollTop) {
        this.timelineCurrentIndex += this.timelineBatchSize
        await this.timelineUpdateVisibleTasks('down')
        this.$nextTick(() => {
          scrollContainer.scrollTop = scrollPosition
        })
      }
      this.prevScrollTop = scrollPosition
    },
    openTimelineTaskModal (task) {
      this.selectedTimelineTask = task
      this.showTimelineTaskModal = true
    },
    closeTimelineTaskModal () {
      this.showTimelineTaskModal = false
      // this.selectedTimelineTask = null
    },
    loadTaskItems () {
      // console.log('loadTaskItems')
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/UserTask/SearchUserTasks', {
        userId: vm.selectedUser,
        date: vm.selectedDate,
        keyword: '',
        pageRequestParameter: {
          isReturnAllDataAndNoPage: true,
          targetPage: 1,
          showCount: 5
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.taskItems = response.data.data
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    beforeDeleteTask (index) {
      this.deleteTaskItem = index
      this.deleteConfirmation = true
    },
    deleteTask () {
      // 在這裡處理刪除任務的邏輯
      this.deleteConfirmation = false
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.delete(apiUrl + '/UserTask/' + vm.deleteTaskItem, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        this.loadTaskItems()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    editTask (index) {
      this.taskItems[index].isEditing = true
    },
    saveTask (index) {
      // console.log(this.taskItems[index])
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.put(apiUrl + '/UserTask/' + vm.taskItems[index].id, {
        task: vm.taskItems[index].task,
        priority: vm.taskItems[index].priority,
        note: vm.taskItems[index].note,
        timeSpent: vm.taskItems[index].timeSpent,
        status: vm.taskItems[index].status,
        executionTime: vm.taskItems[index].executionTime
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.taskItems[index].isEditing = false
        vm.loadTaskItems()
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    cancelEdit (index) {
      this.taskItems[index].isEditing = false
    },
    openAssignTaskDialog (userId, username) {
      this.selectedUser = userId
      this.selectedUserName = username
      this.assignTaskDialog = true
    },
    assignTask () {
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/UserTask', {
        userId: vm.selectedUser,
        task: vm.newTask.task,
        priority: vm.newTask.priority,
        note: vm.newTask.note,
        executionTime: vm.newTask.executionTime
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.loadUsers()
        vm.newTask = {
          task: '',
          priority: '',
          note: '',
          executionTime: ''
        }
        vm.assignTaskDialog = false
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    notifyTask (index) {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/OneSignal/Notifications/Task', {
        taskId: vm.taskItems[index].id
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.$notify({
          group: 'appNotify',
          type: 'success',
          title: 'Notify Successful',
          text: 'The reminder has been successfully sent.'
        })
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    formattedPriority (priorityValue) {
      if (priorityValue === 'VeryHigh') {
        return '<span style="color:red;">Very High</span>'
      } else if (priorityValue === 'High') {
        return '<span>High</span>'
      } else if (priorityValue === 'Medium') {
        return '<span>Medium</span>'
      } else if (priorityValue === 'Low') {
        return '<span>Low</span>'
      } else {
        return '<span>' + priorityValue + '</span>'
      }
    },
    formattedStatus (statusValue) {
      if (statusValue === 'ToDo') {
        return '<span>To-Do</span>'
      } else if (statusValue === 'InProgress') {
        return '<span>In Progress</span>'
      } else if (statusValue === 'Completed') {
        return '<span style="color:green;">Completed</span>'
      } else if (statusValue === 'NotResolvable') {
        return '<span style="color:red;">Not Resolvable</span>'
      } else if (statusValue === 'PendingDiscussion') {
        return '<span style="color:red;">Pending Discussion</span>'
      } else {
        return '<span>' + statusValue + '</span>'
      }
    },
    notify (userId) {
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.post(apiUrl + '/OneSignal/Notifications', {
        userId: userId
      }, {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        vm.$notify({
          group: 'appNotify',
          type: 'success',
          title: 'Notify Successful',
          text: 'The reminder has been successfully sent.'
        })
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    formatDate (dateString) {
      // Implement your date formatting logic here
      // console.log(dateString)
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}/${month}/${day}`
    },
    todayTasks (tasks) {
      const formattedToday = new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replaceAll('-', '/')
      // console.log(formattedToday)
      const tasksToday = []
      tasks.forEach((task) => {
        if (task.executionTime === formattedToday) {
          tasksToday.push(task)
        }
      })

      return tasksToday
    },
    handleSortedOrderTypeOptionChange () {
      // console.log(this.sortedOrderType)
      // if (this.sortedOrderType === 'Last Online Time 最後上線時間') {
      //   // 执行针对 "Last Online Time" 选项的操作
      //   console.log('选择了 Last Online Time 选项')
      // } else if (this.sortedOrderType === 'Today Task Count 今日任務數量') {
      //   // 执行针对 "Today Task Count" 选项的操作
      //   console.log('选择了 Today Task Count 选项')
      // }
    },
    toggleSortOrder () {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
    },
    getSortValue (employee) {
      if (this.sortedOrderType === 'Name 名字') {
        return employee.name
      } else if (this.sortedOrderType === 'Today Task Count 今日任務數量') {
        return this.todayTasks(employee.tasks).length
      } else if (this.sortedOrderType === 'Last Online Time 最後上線時間') {
        return new Date(employee.lastOnlineTime)
      }
    }
  },
  computed: {
    formattedDate () {
      const date = new Date(this.selectedDate)
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    },
    timelineGroupedTasks () {
      const groups = {}

      for (const task of this.timelineVisibleTasks) {
        // console.log(task.executionTime)
        const date = task.executionTime
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(task)
      }

      return groups
    },
    sortedEmployees () {
      // const selfUsername = store.getters.user
      // const employeesCopy = this.employees.slice()
      // const selfEmployee = employeesCopy.find(employee => employee.username === selfUsername)
      // console.log(employeesCopy)

      // if (selfEmployee) {
      //   employeesCopy.splice(employeesCopy.indexOf(selfEmployee), 1)
      //   employeesCopy.unshift(selfEmployee)
      // }

      // return employeesCopy.sort((a, b) => {
      //   const aValue = this.getSortValue(a)
      //   const bValue = this.getSortValue(b)
      //   const multiplier = this.sortOrder === 'asc' ? -1 : 1

      //   if (aValue < bValue) return -1 * multiplier
      //   if (aValue > bValue) return 1 * multiplier
      //   return 0
      // })
      const sortEmployees = this.employees.slice().sort((a, b) => {
        const aValue = this.getSortValue(a)
        const bValue = this.getSortValue(b)
        const multiplier = this.sortOrder === 'asc' ? -1 : 1

        if (aValue < bValue) return -1 * multiplier
        if (aValue > bValue) return 1 * multiplier
        return 0
      })
      const selfUsername = store.getters.user
      const employeesCopy = sortEmployees.slice()
      const selfEmployee = employeesCopy.find(employee => employee.username === selfUsername)
      console.log(employeesCopy)

      if (selfEmployee) {
        employeesCopy.splice(employeesCopy.indexOf(selfEmployee), 1)
        employeesCopy.unshift(selfEmployee)
      }
      return employeesCopy
    }
  }
}
</script>

<style>
#assignTask {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}

.task-list {
  max-height: calc(100vh - 64px); /* Set a maximum height for scrolling */
  overflow-y: scroll;
}
.task-list::-webkit-scrollbar {
    display: none;
}
.placeholder {
  height: 1000px; /* Adjust the height as needed */
}

.custom-list {
  background-color: #f5f5f5; /* Customize the background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Customize the shadow */
  padding: 16px;
}

.custom-list-item {
  margin-bottom: 8px; /* Add some spacing between items */
  border: 1px solid #ccc; /* Add a border to items */
  border-radius: 4px;
}

.custom-list-item-content {
  padding: 8px;
}

.custom-list-item-title {
  font-weight: bold;
}

.custom-list-item-subtitle {
  color: #888;
}

.group-title {
  background-color: #f5f5f5;
  padding: 4px 16px;
  font-weight: bold;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: bold;
}

.priority-badge {
  background-color: #ffcc00; /* Customize badge color */
  color: #333; /* Customize text color */
}

.status-badge {
  background-color: #00cc66; /* Customize badge color */
  color: #fff; /* Customize text color */
}

.assignee-badge {
  background-color: #3366cc; /* Customize badge color */
  color: #fff; /* Customize text color */
}
</style>
