<template>
  <div class="auto-login">
    <h1>Auto Login</h1>
    <p>{{ content }}</p>
    <br>
    <router-link to="/" class="home-link">
      <v-btn size="large" color="teal">回到首頁<br>Go to Home</v-btn>
    </router-link>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'AutoLoginView',
  data: () => ({
    content: 'Checking Token Now...'
  }),
  mounted () {
    var vm = this
    const apiUrl = process.env.VUE_APP_API_URL
    axios.post(apiUrl + '/AutoLoginToken/CheckToken', {
      loginToken: vm.$route.params.token
    }).then((response) => {
      // console.log(response.data) // This will display the response data
      const user = response.data.username
      const token = response.data.token
      const roles = response.data.roles.toString()
      vm.$store.dispatch('loginUser', { user, token, roles })

      vm.$notify({
        group: 'appNotify',
        type: 'success',
        title: 'Login Successful',
        text: 'Welcome to use Remind App'
      })

      vm.$router.push('/todaytask')
    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error('Error:', error)
        vm.$notify({
          group: 'appNotify',
          type: 'error',
          title: 'Login Failed',
          text: 'System issue, please contact the administrator'
        })
        vm.content = 'Login failed, please verify if the token is incorrect or has already been used.'
      })
  }
}
</script>

<style>
.auto-login {
  text-align: center;
  margin-top: 100px;
}

.home-link {
  margin-top: 20px;
  text-decoration: none;
}
</style>
