<template>
  <!-- <HelloWorld /> -->
  <div id="homeView" v-if="user.username">
    <v-container>
      Hi, {{ user.name }}. Welcome to login and use the Remind App. Remind is a powerful task management application designed for modern businesses, and its Chinese name is '唸唸不忘'.
      <br><br><br>
      <v-divider/>
      Test information for convenient testing during app development.<br><br>
      <h4>Your Permission Status</h4>
      Browser Permission: {{ browserPermission }}<br>
      App Permission: {{ appPermission }}<br>
      <v-btn color="primary" @click="openNotify" v-if="!isSubscribe&&isBrowserSupport">Open Notify!</v-btn>
      <v-btn color="primary" @click="closeNotify" v-if="isSubscribe&&isBrowserSupport">Close Notify</v-btn>
      <v-btn color="warn" v-if="!isBrowserSupport">Browser Not Support</v-btn>
    </v-container>
  </div>
  <div id="homeView" v-else>Loading...</div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import store from '../../store'

// Components
// import HelloWorld from '../components/HelloWorld.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data: () => ({
    user: {},
    isSubscribe: false,
    isBrowserSupport: false,
    browserPermission: '',
    appPermission: ''
  }),
  beforeCreate () {
    // const vm = this
  },
  mounted () {
    const vm = this
    const apiUrl = process.env.VUE_APP_API_URL
    axios.get(apiUrl + '/Account', {
      headers: {
        Authorization: `Bearer ${store.getters.token}`
      }
    }).then((response) => {
      // console.log(response)
      vm.user = response.data
    })
      .catch((error) => {
        console.error('Error:', error)
      })
    if ('Notification' in window) {
      vm.isBrowserSupport = true
      console.log(Notification.permission)
      if (Notification.permission === 'granted') {
        vm.browserPermission = 'Accepted'
      } else if (Notification.permission === 'denied') {
        vm.browserPermission = 'Denied'
      } else {
        vm.browserPermission = 'Undecided'
      }
    } else {
      vm.browserPermission = 'Browser Not Support'
      vm.isBrowserSupport = false
    }

    vm.$OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) {
        // console.log('Push notifications are enabled!')
        vm.isSubscribe = true
        vm.appPermission = 'true'

        vm.$OneSignal.getUserId(function (userId) {
          console.log('userId from OneSignal: ' + userId)

          axios.post(apiUrl + '/UserSignalId', { signalId: userId }, {
            headers: {
              Authorization: `Bearer ${store.getters.token}`
            }
          }).then((response) => {
            // console.log(response)
          })
        })
      } else {
        // console.log('Push notifications are not enabled yet.')
        vm.isSubscribe = false
        vm.appPermission = 'false'
      }
    })

    vm.$OneSignal.on('notificationPermissionChange', function (permissionChange) {
      var currentPermission = permissionChange.to
      console.log('New permission state:', currentPermission)

      if (currentPermission === 'granted') {
        vm.$OneSignal.setSubscription(true)
        vm.isSubscribe = true
        vm.browserPermission = 'Accepted'
        vm.appPermission = 'true'
      } else if (currentPermission === 'denied') {
        vm.browserPermission = 'Denied'
        vm.appPermission = 'false'
        vm.$notify({
          group: 'appNotify',
          type: 'warn',
          title: 'Browser Permissions',
          text: 'This app requires enabling notification permissions to function properly.'
        })
      } else {
        vm.browserPermission = 'Undecided'
        vm.appPermission = 'false'
        vm.$notify({
          group: 'appNotify',
          type: 'warn',
          title: 'Browser Permissions',
          text: 'This app requires enabling notification permissions to function properly.'
        })
      }
    })
  },
  methods: {
    askNotificationPermission () {
      const vm = this
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          vm.$OneSignal.setSubscription(true)
          vm.isSubscribe = true
          vm.browserPermission = 'Accepted'
          vm.appPermission = 'true'
        } else if (permission === 'denied') {
          vm.browserPermission = 'Denied'
          vm.appPermission = 'false'
          vm.$notify({
            group: 'appNotify',
            type: 'warn',
            title: 'Browser Permissions',
            text: 'This app requires enabling notification permissions to function properly.'
          })
        } else {
          vm.browserPermission = 'Undecided'
          vm.appPermission = 'false'
          vm.$notify({
            group: 'appNotify',
            type: 'warn',
            title: 'Browser Permissions',
            text: 'This app requires enabling notification permissions to function properly.'
          })
        }
      })
    },
    openNotify () {
      const vm = this
      if (vm.$OneSignal) {
        if ('Notification' in window) {
          if (Notification.permission === 'granted') {
            vm.browserPermission = 'Accepted'
            vm.appPermission = 'true'
            vm.$OneSignal.setSubscription(true)
            vm.isSubscribe = true
            vm.$OneSignal.getUserId(function (userId) {
              console.log('userId from OneSignal: ' + userId)
            })
          } else if (Notification.permission === 'denied') {
            vm.browserPermission = 'Denied'
            vm.appPermission = 'false'
            vm.$notify({
              group: 'appNotify',
              type: 'warn',
              title: 'Browser Permissions',
              text: 'Please first enable browser notification permissions from the top-left corner.'
            })
          } else {
            vm.browserPermission = 'Undecided'
            vm.appPermission = 'false'
            vm.$OneSignal.showSlidedownPrompt()
          }
        } else {
          vm.browserPermission = 'Browser Not Support'
          vm.appPermission = 'false'
          vm.$notify({
            group: 'appNotify',
            type: 'warn',
            title: 'Not Support',
            text: 'Browser does not support notifications'
          })
        }
      }
    },
    closeNotify () {
      const vm = this
      if (vm.$OneSignal) {
        vm.$OneSignal.setSubscription(false)
        vm.isSubscribe = false
        vm.appPermission = 'false'
      }
    }
  }
})
</script>

<style>
#homeView {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>
