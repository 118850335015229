import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

loadFonts()

const app = createApp(App)

app.use(Notifications)
app.use(router)
app.use(store)
app.use(vuetify)
app.use(OneSignalVuePlugin, {
  appId: process.env.VUE_APP_ONESIGNAL,
  subdomainName: 'remind.effectstudio.com.tw',
  allowLocalhostAsSecureOrigin: true,
  notifyButton: {
    enable: false
  }
})

app.mount('#app')
