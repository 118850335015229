<template>
  <!-- <HelloWorld /> -->
  <div id="myAccountView" v-if="user.username">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8">
          <v-card>
            <v-card-title>Information <span v-if="editing">(Editing...)</span></v-card-title>
            <v-card-text>
              <v-text-field v-model="user.name" label="Name 姓名" :readonly="!editing" />
              <v-text-field v-model="user.title" label="Title 職稱" :readonly="!editing" />
              <v-text-field v-model="user.phone" label="Phone 電話" :readonly="!editing" />

              <!-- 新增工作開始時間 -->
              <v-select v-model="user.workStartTime" :items="generateTimeOptions()" label="Work Start Time 工作開始時間" :readonly="!editing"></v-select>

              <!-- 新增工作開始時間 -->
              <v-select v-model="user.workReRemindTime" :items="generateTimeOptions()" label="Work Unfinished Remind Time 未完成工作再次提醒時間" :readonly="!editing"></v-select>

              <!-- 新增工作結束時間 -->
              <v-select v-model="user.workEndTime" :items="generateTimeOptions()" label="Work End Time 工作結束時間" :readonly="!editing"></v-select>

              <v-select
                v-model="user.timeZone"
                :items="timeZones"
                label="Time Zone 時區"
                item-title="label"
                item-value="value"
                :readonly="!editing"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="cancelEditing" :variant="editing ? 'tonal': 'tonal'" :color="editing ? 'red' : 'primary'" v-if="editing">
                Cancel
              </v-btn>
              <v-btn @click="toggleEditing" :variant="editing ? 'tonal': 'tonal'" :color="editing ? 'green' : 'primary'">
                {{ editing ? 'Save' : 'Edit' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div id="myAccountView" v-else>Loading...</div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import store from '../../store'

export default defineComponent({
  name: 'MyAccountView',
  data: () => ({
    user: {},
    editing: false,
    timeZones: store.getters.timeZones
  }),
  mounted () {
    this.getUser()
  },
  methods: {
    getUser () {
      const vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      axios.get(apiUrl + '/Account', {
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      }).then((response) => {
        // console.log(response)
        vm.user = response.data
        vm.editing = false
      })
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    toggleEditing () {
      var vm = this
      const apiUrl = process.env.VUE_APP_API_URL
      if (this.editing) {
        // Send updated data to backend API
        // console.log(vm.user)
        axios.post(apiUrl + `/Account/Update?NewPassword&Name=${vm.user.name}&Title=${vm.user.title}&Phone=${vm.user.phone}&TimeZone=${vm.user.timeZone}&WorkStartTime=${vm.user.workStartTime}&WorkEndTime=${vm.user.workEndTime}&WorkReRemindTime=${vm.user.workReRemindTime}`, {}, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`
          }
        })
          .then(response => {
            // Handle success response
            vm.$notify({
              group: 'appNotify',
              type: 'success',
              title: 'Update Account Successful',
              text: response.data.name + 'has successfully modified the account information.'
            })
          })
          .catch((error) => {
            // Handle error
            console.error('Error:', error)
            vm.$notify({
              group: 'appNotify',
              type: 'error',
              title: 'Update Account Failed',
              text: 'Oops, some errors: ' + error.response.data
            })
          })
      }
      this.editing = !this.editing
    },
    cancelEditing () {
      this.getUser()
    },
    generateTimeOptions () {
      const timeOptions = []
      for (let hour = 7; hour <= 23; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
          timeOptions.push(`${time}:00`)
        }
      }
      return timeOptions
    }
  }
})
</script>
