<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>此頁面不存在<br>The page you are looking for does not exist.</p>
    <br>
    <router-link to="/" class="home-link">
      <v-btn size="large" color="teal">回到首頁<br>Go to Home</v-btn>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
.not-found {
  text-align: center;
  margin-top: 100px;
}

.home-link {
  margin-top: 20px;
  text-decoration: none;
}
</style>
