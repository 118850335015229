<!-- EmployeeInfo.vue -->
<template>
  <!--div id="assignTaskView"></div-->
  <AssignTask :assignTaskType="myAssignTaskType"></AssignTask>
</template>

<script>
import AssignTask from '../../components/AssignTask.vue'
// import _ from 'lodash'

export default {
  components: {
    AssignTask
  },
  data () {
    return {
      myAssignTaskType: 1
    }
  }
}
</script>

<style></style>
