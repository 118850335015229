<template>
  <v-bottom-navigation
    v-model="activeTab"
    :value="activeTab"
    background
    fixed
    grow
    active
    color="teal"
  >
    <v-btn v-for="tab in tabs" :key="tab.icon" @click="changeTab(tab.routeName, tab.routeIndex)">
      <v-icon>{{ tab.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data () {
    return {
      activeTab: 0, // 目前選取的頁面
      tabs: [
        {
          icon: 'mdi-home',
          route: '/home',
          routeIndex: 0,
          routeName: 'home'
        },
        {
          icon: 'mdi-calendar-check',
          route: '/todaytask',
          routeIndex: 1,
          routeName: 'todaytask'
        },
        {
          icon: 'mdi-trophy-award',
          route: '/rewardTarget',
          routeIndex: 2,
          routeName: 'rewardTarget'
        },
        {
          icon: 'mdi-clipboard-arrow-down',
          route: '/targetConcernAssigntask',
          routeIndex: 3,
          routeName: 'targetConcernAssigntask'
        },
        {
          icon: 'mdi-clipboard-arrow-down-outline',
          route: '/assigntask',
          routeIndex: 4,
          routeName: 'assigntask'
        }
        // Add more tabs as needed
      ]
    }
  },
  mounted () {
    var currentUrl = window.location.pathname
    // console.log(`Current URL => ${currentUrl}`)
    const findTab = this.tabs.find(element => element.route === currentUrl)
    // console.log(findTab)
    if (findTab) {
      this.activeTab = findTab.routeIndex
    }
  },
  methods: {
    changeTab (routeName, routeIndex) {
      this.activeTab = routeIndex
      this.$router.push({ name: routeName }) // 切換頁面
    }
  },
  watch: {
    $route (to, from) {
      var currentUrl = window.location.pathname
      const findTab = this.tabs.find(element => element.route === currentUrl)
      if (findTab) {
        this.activeTab = findTab.routeIndex
      } else {
        this.activeTab = 0
      }
    }
  }
}
</script>
