import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
  state: {
    user: localStorage.getItem('EffectRemind_USER') || null,
    token: localStorage.getItem('EffectRemind_TOKEN') || null,
    roles: localStorage.getItem('EffectRemind_ROLES') || '',
    taskPriorities: [
      { label: 'Very High', value: 'VeryHigh' },
      { label: 'High', value: 'High' },
      { label: 'Medium', value: 'Medium' },
      { label: 'Low', value: 'Low' }
    ],
    taskStatuses: [
      { label: 'To-Do', value: 'ToDo' },
      { label: 'In Progress', value: 'InProgress' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Not Resolvable', value: 'NotResolvable' },
      { label: 'Pending Discussion', value: 'PendingDiscussion' }
    ],
    rewardTargetTypes: [
      { label: 'Competition 競賽', value: 'Competition' },
      { label: 'Everyone 人人有獎', value: 'EveryoneWinner' },
      { label: 'Individual 個別指定', value: 'IndividuallyAssigned' }
    ],
    timeZones: [
      { label: 'Eastern Standard Time', value: 'EasternStandardTime' },
      { label: 'Central Standard Time', value: 'CentralStandardTime' },
      { label: 'Mountain Standard Time', value: 'MountainStandardTime' },
      { label: 'Pacific Standard Time', value: 'PacificStandardTime' },
      { label: 'Alaskan Standard Time', value: 'AlaskanStandardTime' },
      { label: 'Hawaiian Standard Time', value: 'HawaiianStandardTime' },
      { label: 'US Eastern Standard Time', value: 'USEasternStandardTime' },
      { label: 'Canada Central Standard Time', value: 'CanadaCentralStandardTime' },
      { label: 'GMT Standard Time', value: 'GMTStandardTime' },
      { label: 'Greenwich Standard Time', value: 'GreenwichStandardTime' },
      { label: 'W. Europe Standard Time', value: 'WEuropeStandardTime' },
      { label: 'Central Europe Standard Time', value: 'CentralEuropeStandardTime' },
      { label: 'Romance Standard Time', value: 'RomanceStandardTime' },
      { label: 'E. Europe Standard Time', value: 'EEuropeStandardTime' },
      { label: 'Turkey Standard Time', value: 'TurkeyStandardTime' },
      { label: 'Israel Standard Time', value: 'IsraelStandardTime' },
      { label: 'Arabian Standard Time', value: 'ArabianStandardTime' },
      { label: 'Russian Standard Time', value: 'RussianStandardTime' },
      { label: 'Taipei Standard Time', value: 'TaipeiStandardTime' },
      { label: 'China Standard Time', value: 'ChinaStandardTime' },
      { label: 'Tokyo Standard Time', value: 'TokyoStandardTime' },
      { label: 'Singapore Standard Time', value: 'SingaporeStandardTime' },
      { label: 'India Standard Time', value: 'IndiaStandardTime' },
      { label: 'South Africa Standard Time', value: 'SouthAfricaStandardTime' }
    ]
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
      if (user == null) {
        localStorage.removeItem('EffectRemind_USER')
      } else {
        localStorage.setItem('EffectRemind_USER', user)
      }
    },
    SET_TOKEN (state, token) {
      state.token = token
      if (token == null) {
        localStorage.removeItem('EffectRemind_TOKEN')
      } else {
        localStorage.setItem('EffectRemind_TOKEN', token)
      }
    },
    SET_ROLES (state, roles) {
      // console.log(roles)
      state.roles = roles
      if (roles == null) {
        localStorage.removeItem('EffectRemind_ROLES')
      } else {
        localStorage.setItem('EffectRemind_ROLES', roles)
      }
    },
    LOGOUT (state) {
      state.user = null
      state.token = null
      localStorage.removeItem('EffectRemind_USER')
      localStorage.removeItem('EffectRemind_TOKEN')
    }
  },
  actions: {
    async verifyToken ({ state, commit }) {
      try {
        const apiUrl = process.env.VUE_APP_API_URL
        // 呼叫後端API驗證Token
        // console.log(state.token)
        const response = await axios.get(apiUrl + '/Account', {
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        })

        // console.log(response.data)

        // 如果API回傳成功（200），表示Token有效，什麼都不做
        if (response.status === 200) {
          console.log(response.data)
          commit('SET_USER', response.data.username)
          commit('SET_TOKEN', response.data.token)
          return 200
        }

        // 否則，將Token設為null，表示使用者尚未登入或Token已過期
        commit('SET_USER', null)
        commit('SET_TOKEN', null)
        return 401
      } catch (error) {
        // 發生錯誤時，也將Token設為null
        commit('SET_USER', null)
        commit('SET_TOKEN', null)
        return 401
      }
    },
    loginUser ({ commit }, { user, token, roles }) {
      // console.log('user: ' + user)
      // console.log('token: ' + token)
      // console.log('roles: ' + roles)
      commit('SET_USER', user)
      commit('SET_TOKEN', token)
      commit('SET_ROLES', roles)
    },
    logoutUser ({ commit }) {
      commit('LOGOUT')
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.token !== null // 如果Token存在，表示使用者已登入
    },
    token (state) {
      return state.token
    },
    user (state) {
      return state.user
    },
    roles (state) {
      return state.roles
    },
    taskPriorities (state) {
      return state.taskPriorities
    },
    taskStatuses (state) {
      return state.taskStatuses
    },
    rewardTargetTypes (state) {
      return state.rewardTargetTypes
    },
    timeZones (state) {
      return state.timeZones
    }
  }
})

export default store
